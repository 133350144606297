<template>
  <div class="page-content">
    <div class="content-box">
      <div v-loading="loading">
        <section-title title="学生实践轨迹" />
        <div>
          <el-row class="u-flex mb-16">
            <div class="mr-8">学生姓名：</div>
            <div>
              {{
                internshipInfo.StudentInfo
                  ? internshipInfo.StudentInfo.Name
                  : ""
              }}
            </div>
          </el-row>
          <el-row class="u-flex mb-16">
            <div class="mr-8">实践企业：</div>
            <div>{{ internshipInfo.EnterpriseName }}</div>
          </el-row>
          <el-row class="u-flex mb-16">
            <div class="mr-8">实践岗位：</div>
            <div>{{ internshipInfo.JobName }}</div>
          </el-row>
          <el-row class="u-flex mb-16">
            <div class="mr-8">实践状态：</div>
            <div>{{ internshipInfo.Status_V }}</div>
          </el-row>
          <el-row class="u-flex mb-16">
            <div class="mr-8">实践时间：</div>
            <div>
              {{
                filterTimeStr(
                  internshipInfo.ActualStartTime,
                  internshipInfo.ActualEndTime
                )
              }}
            </div>
          </el-row>
        </div>
      </div>
      <div>
        <section-title title="打卡信息" />
        <el-row class="mb-24">
          <span>日期：</span>
          <el-date-picker
            class="mr-20"
            v-model="queryParams.Date"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
          <span>打卡类型：</span>
          <el-select class="mr-20" v-model="queryParams.SignType" clearable>
            <el-option
              v-for="item in typeList"
              :key="item.label"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-button type="primary" @click="getTableData">搜索</el-button>
        </el-row>
        <div>
          <el-table
            :data="tableData"
            style="width: 100%"
            border
            v-loading="tableLoading"
            height="340px"
          >
            <template v-for="(item, index) in tableConfig">
              <el-table-column
                :key="index"
                :label="item.label"
                :prop="item.prop"
              >
                <template slot-scope="scope">
                  <span v-if="item.prop === 'Date'">{{
                    formartDate(scope.row.SignTime, "day")
                  }}</span>
                  <span v-else-if="item.prop === 'Time'">{{
                    formartDate(scope.row.SignTime, "h-m-s")
                  }}</span>
                  <span v-else-if="item.prop === 'SignType'">
                    {{ scope.row["SignType"] === 1 ? "上班卡" : "下班卡" }}
                  </span>
                  <span v-else>{{ scope.row[item.prop] }}</span>
                </template></el-table-column
              >
            </template>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SectionTitle from "@/components/SectionTitle.vue";
import { getInfo } from "@/api/internshipImplement";
import { getSignDetailPageList } from "@/api/signRecord";
import contentTools from "@/mixins/content-tools";
export default {
  props: {
    isView: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ""
    }
  },
  components: {
    SectionTitle
  },
  mixins: [contentTools],
  data() {
    return {
      loading: false,
      tableLoading: false,
      internshipInfo: "",
      tableData: [],
      tableConfig: [
        {
          label: "日期",
          prop: "Date"
        },
        {
          label: "时间",
          prop: "Time"
        },
        {
          label: "打卡类型",
          prop: "SignType"
        },
        {
          label: "打卡地点",
          prop: "Address"
        },
        {
          label: "打卡状态",
          prop: "SignStatusDescription"
        },
        {
          label: "备注",
          prop: "Remark"
        }
      ],
      queryParams: {
        PageIndex: 1,
        PageSize: 999,
        SignType: ""
      },
      typeList: [
        { label: "全部", value: "" },
        { label: "上班卡", value: 1 },
        { label: "下班卡", value: 0 }
      ]
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      try {
        this.loading = true;
        await this.getInternshipInfo();
        await this.getTableData();
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
    async getInternshipInfo() {
      const res = await getInfo(this.id);
      if (res.IsSuccess) {
        this.internshipInfo = res.Result;
      }
    },
    async getTableData() {
      try {
        this.tableLoading = true;
        if (this.queryParams.Date) {
          let [startTime, endTime] = this.queryParams.Date;
          this.queryParams.StartTime = startTime;
          this.queryParams.EndTime = endTime;
        } else {
          this.queryParams.StartTime = "";
          this.queryParams.EndTime = "";
        }
        this.queryParams.ImplementId = this.id;
        const res = await getSignDetailPageList(this.queryParams);
        if (res.IsSuccess) {
          this.tableData = res.Result.Data;
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.tableLoading = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.content-box {
  width: 850px;
  margin: 0 auto;
}
</style>
